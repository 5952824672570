import React, { useState } from "react";
import { useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./FooterMain.css";

// baseURL
const baseURL = "https://digitalbackend.techsistltd.com";
const FooterMain = () => {
  const [home, setHome] = useState([]);
  useEffect(() => {
    fetch(`${baseURL}/latest_company_details/`)
      .then((response) => {
        // for avoiding error, here using try, catch block
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => setHome(data))
      .catch((error) => console.log(error));
  }, []);
  return (
    <div>
      <div className="main-footer">
        <footer id="footer" className="pt-5">
          <Container className="mt-5">
            <Row>
              {/* first column start */}
              <Col md={4}>
                <AnimationOnScroll animateIn="animate__fadeInUp">
                  <h4 className="mb-3">
                    {home?.data?.footer?.title}
                    <div className="underline">
                      <span></span>
                    </div>
                  </h4>
                  <p className="pb-2">{home?.data?.footer?.description}</p>
                </AnimationOnScroll>
              </Col>
              {/* first column end */}

              <Col md={1}></Col>

              {/* second column start */}
              <Col md={2}>
                <AnimationOnScroll animateIn="animate__fadeInUp" delay={300}>
                  <h4 className="mb-3">
                    Services
                    <div className="underline">
                      <span></span>
                    </div>
                  </h4>
                  <ul className="text-light pb-1">
                    <li className="link">
                      <a
                        className="text-decoration-none text-light"
                        href="http://it.techsistltd.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        IT Services
                      </a>
                    </li>
                    <li className="link ">
                      <a
                        className="text-decoration-none text-light"
                        href="http://business.techsistltd.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Business Services
                      </a>
                    </li>
                    <li className="link ">
                      <a
                        className="text-decoration-none text-light"
                        href="http://digital.techsistltd.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Digital Services
                      </a>
                    </li>
                    <li className="link">
                      <a
                        className="text-decoration-none text-light"
                        href="http://lifestyle.techsistltd.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lifestyle Services
                      </a>
                    </li>
                    <li className="link">
                      <a
                        className="text-decoration-none text-light"
                        href="http://studio.techsistltd.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Studio Services
                      </a>
                    </li>
                    <li className="link">
                      <a
                        className="text-decoration-none text-light"
                        href="http://event.techsistltd.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Event Services
                      </a>
                    </li>
                  </ul>
                  {/* <ul>
                  {services?.data?.service_group?.map(({ title, slug }) => (
                    <li>
                      <Link className="link" to="/services" state={{ slug }}>
                        {`${title}`}
                      </Link>
                    </li>
                  ))}
                </ul> */}
                </AnimationOnScroll>
              </Col>
              {/* second column end */}

              <Col md={1}></Col>

              {/* third column start */}
              <Col md={2}>
                <AnimationOnScroll animateIn="animate__fadeInUp" delay={600}>
                  <h4 className="mb-3">
                    About{" "}
                    <div className="underline">
                      <span></span>
                    </div>
                  </h4>
                  <ul className="pb-2">
                    <li>
                      <Link className="link" to="/about">
                        Our Story
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to="/about#vision">
                        Vision
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to="/a-team">
                        Team
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to="/careers">
                        Careers
                      </Link>
                    </li>
                    <li>
                      <a
                        className="link"
                        href="https://digitalbackend.techsistltd.com/account/login/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Official
                      </a>
                    </li>
                    <li></li>
                    <Link
                      to="/meetingMinutes"
                      className="text-decoration-none text-light"
                    >
                      Techsist x Client Meeting
                    </Link>
                  </ul>
                </AnimationOnScroll>
              </Col>
              {/* third column end */}

              {/* social links column start */}
              <Col md={2}>
                <AnimationOnScroll animateIn="animate__fadeInUp" delay={900}>
                  <h4 className="mb-3">
                    Follow Us{" "}
                    <div className="underline">
                      <span></span>
                    </div>
                  </h4>
                  <ul className="social-icons">
                    {home?.data?.social_media?.map(
                      ({ id, title, url, icon }) => (
                        <li key={id}>
                          <a
                            className="link"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i
                              className={`fab fa-${title.toLowerCase()} me-3`}
                            ></i>{" "}
                            {title?.charAt(0).toUpperCase() + title?.slice(1)}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </AnimationOnScroll>
              </Col>
              {/* social links column end */}
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default FooterMain;
