import React from "react";
import { Link } from "react-router-dom";
import anni from "../../../images/footer-annimation.gif";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="container-fluid contact-background">
      <div className="container py-lg-5" id="contact-container">
        <div className="contact-container  mx-auto row align-items-center p-lg-4 p-md-4  ">
          <div className="col-8 col-sm-6 col-md-7 col-lg-9 lets-collaborate-text px-lg-3">
            <h1>Let’s Collaborate and</h1>
            <h2>Build something great</h2>
          </div>
          {/* <div className="col-12 col-sm-12 col-md-5 col-lg-3 lets-collaborate-contact-box d-flex align-items-center justify-content-between">
            <span>Contact</span>
            <img src={anni} alt="" className="img-fluid " />
          </div> */}
          <div className="col-4 col-sm-6 col-md-5 col-lg-3   ">
            <div className="lets-collaborate-contact-box">
              <a
                href="http://techsistltd.com/contact"
                className="text-decoration-none d-flex justify-content-between align-items-center"
              >
                <span>Contact</span>
                <img src={anni} alt="" className="img-fluid " />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
