import React from "react";
import "./ContactForm.css";
import { useForm } from "react-hook-form";
import { FaArrowRight } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { useState } from "react";

const ContactForm = ({ serviceId, sub_serviceId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("service_description", serviceId);
    formData.append("service_sub_description", sub_serviceId);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("company_name", data.company_name);
    formData.append("phone", data.phone);
    formData.append("message", data.message);
    formData.append("file", data.file[0]);
    axios
      .post("https://digitalbackend.techsistltd.com/service_message/", formData)

      .then(function (response) {
        if (response.data.status) {
          Swal.fire(
            "Thank you!",
            "An executive from Techsist will get in touch with you shortly",
            "success"
          );
          reset();
        } else {
          alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="container" id="contact-form">
      <h1 className="contact-form-title py-3">
        Get a direct quotation from us!
      </h1>
      <form className="row g-5 px-2 py-3 align-items-center" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-6">
          <label htmlFor="Name" className="form-label label-title">
            Name
          </label>
          <input
            type="text"
            className=" input-field-style"
            id="Name"
            placeholder="Enter your name"
            {...register("name", { required: true })}
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="Email" className="form-label label-title">
            Email
          </label>
          <input
            type="email"
            className=" input-field-style"
            id="Email"
            placeholder="Ex: info@techsistltd.com"
            {...register("email", { required: true })}

          />
        </div>

        <div className="col-md-6">
          <label htmlFor="Phone" className="form-label label-title">
            Phone
          </label>
          <input
            type="text"
            className=" input-field-style"
            id="Phone"
            placeholder="+880"
            {...register("Phone", { required: true })}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="Company" className="form-label label-title">
            Company
          </label>
          <input
            type="text"
            className=" input-field-style"
            id="Company"
            placeholder="Ex: Techsist"
            {...register("company_name", { required: true })}
          />
        </div>
        <div className="col-md-12">
          <label htmlFor="Message" className="form-label label-title">
            Message
          </label>
          <textarea
            type="text"
            className=" input-field-style"
            id="Message"
            placeholder="Please describe your project"
            {...register("message", { required: true })}
          />
        </div>
        <div className="col-6">
          <input
            type="file"
            style={{ display: "none" }}
            id="file"
            name="file"
            {...register("file")}
          />
          <label htmlFor="file" className="form-label label-add-title mb-0">
            + <span className="add-attachment">Add Attachment</span>
          </label>
        </div>

        <div className="col-6 text-end">
          <div className="send-container ">
            <input
              className="me-3 send-btn-style"
              type="submit"
              value={isLoading ? "Sending..." : "Submit"}
            />
            <div className="send-icon-holder text-center">
              <FaArrowRight type="submit" className="arrow-icon"></FaArrowRight>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
