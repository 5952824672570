import React from 'react';
import "./Footers.css";

const Footers = () => {
    return (
        <div className='container-fluid footers-last' >
            <h3 className='text-center footers py-4 m-0'>Copyright © 2022 | Techsist  All Right Reserved</h3>
        </div>
    );
};

export default Footers;
