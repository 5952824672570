import React from "react";
import "./Developments.css";
import { useState } from "react";
import ServiceHolder from "../ServiceHolder/ServiceHolder";
import ServicesButton from "./ServicesButton/ServicesButton";
import ContactFormModal from "../ContactFormModal/ContactFormModal";
// import ContactForm from "./ContactForm/ContactForm";

const Developments = ({ develops }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const { title, description, icon_url, service_sub_description, id } =
    develops;
  // console.log(service_sub_description);
  const [subSer, setSubSer] = useState([]);

  // serviceid for contact form
  const [serviceId, setServiceId] = useState();

  const filterService = (id) => {
    let url = `https://digitalbackend.techsistltd.com/service_sub_description/${id}/`;
    // console.log(url);
    fetch(url)
      .then((res) => res.json())
      .then((data) => setSubSer(data.data));
    // const subService = develop.filter(subSr => id === subSr.id)
    // console.log(subService)
    // setSubSer(subService);
  };

  return (
    <>
      <div className="col">
        <div className=" h-100 service-card border-0">
          <img src={icon_url} className=" img-fluid service-img" alt="..." />
          <div className="service-body-text">
            <h5 className="service-title">{title}</h5>
            <p className="service-description m-0">{description}</p>
          </div>
          <div className="service-card-footer border-0">
            {service_sub_description !== false ? (
              <a
                type="button"
                data-bs-toggle="modal"
                data-bs-target={`#example-${id}`}
                href="/"
                style={{ textDecoration: "none", textAlign: "center" }}
              >
                <p onClick={() => filterService(id)}>
                  <ServicesButton>{"All Services"}</ServicesButton>
                </p>
              </a>
            ) : (
              <p
                onClick={() => {
                  handleShow();
                  setServiceId(id);
                }}
                style={{ cursor: "pointer" }}
              >
                <ServicesButton>{"Get Quotation"}</ServicesButton>
              </p>
            )}
          </div>
        </div>
      </div>
      <>
        {/* form modal  */}
        <ContactFormModal
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
          serviceId={serviceId}
        ></ContactFormModal>
        <div
          className="modal fade modal-bg "
          id={`example-${id}`}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header service-modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                <button
                  type="button"
                  className="btn-close close-sign"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body text-white w-100 ">
                <div className=" h-100">
                  {<ServiceHolder subSer={subSer}></ServiceHolder>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Developments;
