import React from "react";
import ModalCard from "../ModalCard/ModalCard";
import "./ServiceHolder.css";

const ServiceHolder = ({ subSer }) => {
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 mx-auto g-4 py-3 service-holder-container">
      {subSer
        ? subSer?.map((service) => (
          <ModalCard service={service} id={service.id}></ModalCard>
        ))
        : "No Data Found"}
    </div>
  );
};

export default ServiceHolder;
