import React from "react";
import Footer from "../Shared/Footer/Footer";
import Footers from "../Shared/Footers/Footers";
import FooterMain from "../Shared/FooterMain/FooterMain";
import Navigation from "../Shared/Navigation/Navigation";
import Banner from "./Banner/Banner";
import Contact from "./Contact/Contact";
import ContactUs from "./ContactUs/ContactUs";
import Development from "./Development/Development";

const Home = () => {
  return (
    <>
      <Navigation></Navigation>
      <Banner></Banner>
      {/* <Solution></Solution> */}
      <Development></Development>
      {/* <Security></Security> */}
      {/* <Contact></Contact> */}
      <ContactUs></ContactUs>
      {/* <Footer></Footer> */}
      <FooterMain></FooterMain>
      <Footers></Footers>
    </>
  );
};

export default Home;
