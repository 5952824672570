import React from "react";
import Modal from "react-bootstrap/Modal";
import ContactForm from "../../Shared/ContactForm/ContactForm";
import "./ContactFormModal.css";

const ContactFormModal = ({ handleShow, handleClose, show, serviceId }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ContactForm serviceId={serviceId}></ContactForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactFormModal;
